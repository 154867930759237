const RATETYPE = {
  ACCOUNT: 'Account',
  HOTEL: 'Hotel',
  AIRLINE: 'Airline',
  TRAIN: 'Train',
  BUS: 'Bus',
};

const AIRLINEORDERRATE = {
  'Percent1.5': '1,5%',
  Percent2: '2%',
  Percent4: '4%',
  Percent5: '5%',
  Fixed520: '520',
  Fixed500: '500',
  Fixed490: '490',
  Fixed430: '430',
  Fixed400: '400',
  Fixed370: '370',
  Fixed330: '330',
  Fixed300: '300',
  Fixed290: '290',
  Fixed280: '280',
  Fixed270: '270',
  Fixed250PlusPercent2: '250 + 2%',
  Fixed250PlusPercent1: '250 + 1%',
  Fixed250: '250',
  Fixed220: '220',
  Fixed190: '190',
  Fixed129: '129',
  Fixed99: '99',
  Fixed0: '0',
};

const AIRLINE_ORDER_RATE_FOR_SMARTAGENT = {
  ...AIRLINEORDERRATE,
  Fixed100: '100',
};

const AIRLINECHANGERATE = {
  Fixed0: '0',
  Fixed190: '190',
  Fixed250: '250',
  Fixed500: '500',
};

const AIRLINE_CHANGE_RATE_FOR_SMARTAGENT = {
  ...AIRLINECHANGERATE,
  Fixed150: '150',
};

const TRAINORDERRATE = {
  Percent5: '5%',
  Fixed520: '520',
  Fixed500: '500',
  Fixed490: '490',
  Fixed300: '300',
  Fixed280: '280',
  Fixed270: '270',
  Fixed290: '290',
  Fixed250: '250',
  Fixed220: '220',
  Fixed190: '190',
  Fixed160: '160',
  Fixed135: '135',
  Fixed129: '129',
  Fixed100: '100',
  Fixed99: '99',
  Fixed90: '90',
  Fixed70: '70',
  Fixed75: '75',
  Fixed50: '50',
  Fixed0: '0',
};

const TRAIN_ORDER_FOR_SMARTAGENT = {
  ...TRAINORDERRATE,
  Fixed150: '150',
};

const TRAINCANCELRATE = {
  Fixed500: 500,
  Fixed250: 250,
  Fixed190: 190,
  Fixed100: 100,
  Fixed0: 0,
};

const TRAIN_CANCEL_RATE_FOR_SMARTAGENT = {
  ...TRAINCANCELRATE,
  Fixed75: 75,
  Fixed150: 150,
};

const BUS_ORDER_RATE = {
  Fixed0: '0',
  Fixed180: '180',
  Fixed250: '250',
};

const DEFAULT_SW_COMISSION = 'Fixed180';

const HOTELORDERRATE = {
  Minus3Percent: 'Скидка 3%',
  Minus5Percent: 'Скидка 5%',
  Variable6Percent: '6%',
  Minus7Percent: 'Скидка 7%',
  Minus15Percent: 'Скидка 15%',
  Variable8Percent: '8%',
  Variable8PercentAndHalf: '8.5%',
  Variable10Percent: '10%',
  Variable11Percent: '11%',
  Variable11PercentAndHalf: '11.5%',
  Variable12Percent: '12%',
  Variable13PercentAndHalf: '13.5%',
  Variable15Percent: '15%',
  LikeHotel25Percent: 'Для клиентов ОБС',
  LikeHotel: 'Как в отеле',
  SmartAgent10: 'Smartagent -10%',
  SmartAgent12: 'Smartagent -12%',
  SmartAgent8: 'Smartagent -8%',
  SmartAgentLight: 'Smartagent light',
  SmartAgentATH: 'Smartagent ATH',
  SmartAgentTM: 'Smartagent TM',
};

export {
  RATETYPE,
  AIRLINEORDERRATE,
  AIRLINECHANGERATE,
  AIRLINE_CHANGE_RATE_FOR_SMARTAGENT,
  TRAINORDERRATE,
  TRAIN_CANCEL_RATE_FOR_SMARTAGENT,
  TRAINCANCELRATE,
  BUS_ORDER_RATE,
  DEFAULT_SW_COMISSION,
  HOTELORDERRATE,
  TRAIN_ORDER_FOR_SMARTAGENT,
  AIRLINE_ORDER_RATE_FOR_SMARTAGENT,
};
